import { ActionContext } from 'vuex';
import axiosAuth from '@/config/axios';
import { API_URL } from '@/config/api';
import { Client, FilterParams } from '../types/client';
import { useNotification } from '@/composables/useNotification';
import { FormMode, defaultClient } from '../consts';
import { ClientState } from '../types/state';
import { ClientServices } from '@/modules/client_services/types/client_services';
import { ClientAddresses } from '@/modules/client_addresses/types/client_addresses';
import { DefaultResponse } from '@/types/response';
import { IRootState } from '@/types/state';


const { notifySuccess, notifyError } = useNotification();

const state: () => ClientState = () => ({
  addresses: [],
  clients: [],
  services: [],
  currentClientId: '',
  filteredClients: [],
  filterClient: {
    id: '',
    name: '',
  },
  formMode: FormMode.Add,
  isCreateDialogOpen: false,
  pagination: {
    page: 1,
    itemsPerPage: 10,
    total: 0,
  },
  paginationServices: {
    page: 1,
    itemsPerPage: 10,
    total: 0,
  },
  selectedClient: defaultClient,
  selectedClientEmployees: [],


});

const getters = {
  getCurrentClientId: (state: ClientState) => {
    return state.currentClientId;
  },
}

const mutations = {
  SET_CURRENT_CLIENT_ID(state: ClientState, payload: { clientId: string }) {
    state.currentClientId = payload.clientId;
  },
  SET_CLIENTS(state: ClientState, payload: { clients: Client[] }) {
    state.clients = payload.clients;
    state.filteredClients = payload.clients;
  },
  SET_FILTERED_CLIENTS(state: ClientState, filteredClients: Client[]) {
    state.filteredClients = filteredClients;
  },
  SET_CREATE_DIALOG_OPEN(state: ClientState, isOpen: boolean) {
    state.isCreateDialogOpen = isOpen;
  },
  SET_SELECTED_CLIENT(state: ClientState, client: Client) {
    state.selectedClient = Object.assign({}, client);
  },
  SET_FORM_MODE(state: ClientState, mode: FormMode) {
    state.formMode = mode;
  },
  SET_ADDRESSES(state: ClientState, addresses: ClientAddresses[]) {
    state.addresses = addresses;
  },
  SET_SERVICES(state: ClientState, addresses: ClientServices[]) {
    state.services = addresses;
  },
  SET_TOTAL_ITEMS(state: ClientState, payload: { total: number }) {
    state.pagination.total = payload.total;
  },
};

const actions = {
  async fetchClientsById({ commit }: ActionContext<ClientState, IRootState>, payload: { clientId: string }) {
    try {
      const response = await axiosAuth.get<Client>(`${API_URL}/clients/${payload.clientId}`);
      commit('SET_SELECTED_CLIENT', response.data);
    } catch (error) {
      notifyError('Erro ao buscar cliente');
      console.error('Erro ao buscar cliente:', error);
    }
  },
  async fetchClients({ commit, state }: ActionContext<ClientState, IRootState>) {
    try {
      const response = await axiosAuth.get<DefaultResponse<Client>>(`${API_URL}/clients`, {
        params: {
          name: state.filterClient.name,
        }
      });
      commit('SET_CLIENTS', {clients: response.data.data});
    } catch (error) {
      notifyError('Erro ao buscar clientes');
      console.error('Erro ao buscar clientes:', error);
    }
  },
  async createClient({ dispatch, state }: ActionContext<ClientState, IRootState>) {
    const { ...clientToSave } = state.selectedClient;


    try {
      await axiosAuth.post(`${API_URL}/clients`, clientToSave);
      notifySuccess('Cliente criado com sucesso');
      dispatch('fetchClients');
    } catch (error) {
      console.error('Erro ao criar cliente:', error);
      notifyError('Erro');
    }
  },
  async updateClient({ dispatch, state }: ActionContext<ClientState, IRootState>) {
    const { id, ...clientToUpdate } = state.selectedClient;
    try {
      await axiosAuth.put(`${API_URL}/clients/${id}`, clientToUpdate);
      notifySuccess('Cliente atualizado com sucesso');
      dispatch('fetchClients');
    } catch (error) {
      notifyError('Erro');
      console.error('Erro ao atualizar cliente:', error);
    }
  },
  async changeStatusClient({ dispatch }: ActionContext<ClientState, IRootState>, id: string) {
    try {
      await axiosAuth.put(`${API_URL}/clients/${id}/status`);
      notifySuccess('Status do cliente alterado com sucesso');
      dispatch('fetchClients');
    } catch (error) {
      notifyError('Erro');
      console.error('Erro ao alterar status do cliente:', error);
    }
  },
  async deleteClient({ dispatch }: ActionContext<ClientState, IRootState>, id: string) {
    try {
      await axiosAuth.delete(`${API_URL}/clients/${id}`);
      notifySuccess('Cliente deletado com sucesso');
      dispatch('fetchClients');
    } catch (error) {
      notifyError('Erro');
      console.error('Erro ao deletar cliente:', error);
    }
  },
  openCreateDialog({ commit }: ActionContext<ClientState, IRootState>) {
    commit('SET_FORM_MODE', FormMode.Add);
    commit('SET_SELECTED_CLIENT', defaultClient);
    commit('SET_CREATE_DIALOG_OPEN', true);
  },
  openEditDialog({ commit }: ActionContext<ClientState, IRootState>, client: Client) {
    commit('SET_FORM_MODE', FormMode.Edit);
    commit('SET_SELECTED_CLIENT', client);
    commit('SET_CREATE_DIALOG_OPEN', true);
  },
  async openScreenDetails({ dispatch, commit }: ActionContext<ClientState, IRootState>, payload: { clientId: string }) {
    try {
      commit('SET_CURRENT_CLIENT_ID', { clientId: payload.clientId })

      dispatch('fetchClientsById', { clientId: payload.clientId });
      dispatch('client_addresses/getClientAddresses', { clientId: payload.clientId }, { root: true });
      dispatch('client_services/getClientServices', { clientId: payload.clientId }, { root: true });
      dispatch('services/fetchServices', {}, { root: true });
      dispatch('client_employee/fetchClientsEmployees', { clientId: payload.clientId }, { root: true });
    } catch (error) {
      notifyError('Erro ao buscar informações do cliente');
      console.error('Erro ao buscar informações do cliente:', error);
    }
  },


  openViewDialog({ commit }: ActionContext<ClientState, IRootState>, client: Client) {
    commit('SET_FORM_MODE', FormMode.View);
    commit('SET_SELECTED_CLIENT', client);
    commit('SET_CREATE_DIALOG_OPEN', true);
  },
  closeCreateDialog({ commit }: ActionContext<ClientState, IRootState>) {
    commit('SET_CREATE_DIALOG_OPEN', false);
  },
};



export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
