import { createStore } from 'vuex'
import authStore from '../modules/auth/store'
import avaliationsStore from '../modules/avaliations/store'
import clientEmployeeStore from '../modules/client_employee/store'
import clientStore from '../modules/client/store'
import employeeStore from '../modules/employee/store'
import menuStore from '../modules/menu/store'
import serviceStore from '../modules/services/store'
import stockItemStore from '../modules/stock/modules/stock_items/store'
import clientsServicesSchedulesStore from '../modules/clients_services_schedule/store'
import clientServicesStore from '../modules/client_services/store'
import clientAddressesStore from '../modules/client_addresses/store'
import responsiblesStore from '../modules/responsibles/store'
import stockControlStore from '../modules/stock/modules/stock_control/store'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: authStore,
    avaliations: avaliationsStore,
    client_employee: clientEmployeeStore,
    client_services: clientServicesStore,
    client_addresses: clientAddressesStore,
    clients_services_schedules: clientsServicesSchedulesStore,
    clients: clientStore,
    employees: employeeStore,
    menu: menuStore,
    responsibles: responsiblesStore,
    services: serviceStore,
    stock_control: stockControlStore,
    stock_items: stockItemStore,
  }
})
