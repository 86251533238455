import { ActionContext } from 'vuex';
import axiosAuth from '@/config/axios';
import { API_URL } from '@/config/api';
import { useNotification } from '@/composables/useNotification';
import { FormMode, defaultClientAddresses } from '../consts';
import { ClientAddresesState } from '../types/state';
import { ClientAddresses } from '../types/client_addresses';
import { IRootState } from '@/types/state';

const { notifySuccess, notifyError } = useNotification();

const state: () => ClientAddresesState = () => ({
  clientAddresses: [],
  formMode: FormMode.Add,
  isCreateDialogOpen: false,
  pagination: {
    page: 1,
    itemsPerPage: 10,
    total: 0,
  },
  selectedClientAddresses: defaultClientAddresses,
  addressSelectedFormated: "",
});

const getters = {
  getAddressSelected: (state: ClientAddresesState) => {
    return state.selectedClientAddresses;
  },
}

const mutations = {
  SET_SELECTED_CLIENT_ADDRESSES(state: ClientAddresesState, payload: { addressSelected: ClientAddresses }) {
    state.selectedClientAddresses = Object.assign({}, payload.addressSelected);
  },
  SET_FORM_MODE(state: ClientAddresesState, payload: { mode: FormMode }) {
    state.formMode = payload.mode;
  },
  SET_CREATE_DIALOG_OPEN(state: ClientAddresesState, payload: { isOpen: boolean }) {
    state.isCreateDialogOpen = payload.isOpen;
  },
  SET_CLIENT_ADDRESSES(state: ClientAddresesState, clientAddreses: ClientAddresses[]) {
    state.clientAddresses = clientAddreses;
  },
  SET_TOTAL_ITEMS(state: ClientAddresesState, payload: { total: number }) {
    state.pagination.total = payload.total;
  },
  SET_ADDRESS_FORMATED(state: ClientAddresesState, payload: { addressFormated: string }) {
    state.addressSelectedFormated = payload.addressFormated
  },
};

const actions = {
  setClientAddresSelectedAndFormated({ commit, state }: ActionContext<ClientAddresesState, IRootState>, payload: { idAddress: string }) {
    const addressSelected = state.clientAddresses.find((address) => address.id === payload.idAddress)
    const addressFormated = `${addressSelected?.address}, ${addressSelected?.addressNeighborhood}, ${addressSelected?.addressNumber} - ${addressSelected?.addressCity}`
    commit("SET_SELECTED_CLIENT_ADDRESSES", { addressSelected: addressSelected })
    commit("SET_ADDRESS_FORMATED", { addressFormated: addressFormated })
  },
  openCreateDialog({ commit }: ActionContext<ClientAddresesState, IRootState>) {
    commit('SET_FORM_MODE', { mode: FormMode.Add });
    commit('SET_SELECTED_CLIENT_ADDRESSES', { addressSelected: defaultClientAddresses });
    commit('SET_CREATE_DIALOG_OPEN', { isOpen: true });
  },
  closeCreateDialog({ commit }: ActionContext<ClientAddresesState, IRootState>) {
    commit('SET_FORM_MODE', { mode: FormMode.View });
    commit('SET_SELECTED_CLIENT_ADDRESSES', { addressSelected: defaultClientAddresses });
    commit('SET_CREATE_DIALOG_OPEN', { isOpen: false });
  },


  async getClientAddresses({ commit }: ActionContext<ClientAddresesState, IRootState>, payload: { clientId: string }) {
    try {
      const response = await axiosAuth.get<ClientAddresses[]>(`${API_URL}/client_addresses/${payload.clientId}`);
      commit('SET_CLIENT_ADDRESSES', response.data);

    } catch (error) {
      notifyError('Erro ao buscar endereços');
      console.error('Erro ao buscar endereços:', error);
    }
  },
  async saveClientAddress({ dispatch, state, rootGetters }: ActionContext<ClientAddresesState, IRootState>) {
    const { ...clientAddressToSave } = state.selectedClientAddresses;
    const currentClientId = rootGetters['clients/getCurrentClientId'];
    clientAddressToSave.clientId = currentClientId

    try {
      await axiosAuth.post(`${API_URL}/client_addresses`, clientAddressToSave);
      notifySuccess('Endereço criado com sucesso');
      dispatch('getClientAddresses', { clientId: currentClientId });
      dispatch('closeCreateDialog')
    } catch (error) {
      console.error('Erro ao criar endereço para o cliente:', error);
      notifyError('Erro');
    }
  },

};



export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
