import { FormMode } from "@/consts/form";
import { ResponsiblesState } from "../types/state";
import { ActionContext } from "vuex";
import axiosAuth from "@/config/axios";
import { DefaultResponse } from "@/types/response";
import { endPoints } from "@/config/api";
import { useNotification } from "@/composables/useNotification";
import { Responsibles } from "../types/responsibles";
import { IRootState } from "@/types/state";
import { defaultClient } from "@/modules/client/consts";

const { notifySuccess, notifyError } = useNotification();

const defaultResponsibles: Responsibles = {
    id: '',
    inspectionDate: new Date(),
    roomOrganized: false,
    hasTimeline: false,
    clientPresent: false,
    clientId: '',
    client: defaultClient,
    createdAt: new Date(),
    createdBy: '',
};



const state: () => ResponsiblesState = () => ({
    responsibles: [],
    pagination: {
        page: 1,
        itemsPerPage: 10,
        total: 0
    },
    selectedResponsible: Object.assign({}, defaultResponsibles),
    formMode: FormMode.ADD,
    showFormDialog: false,
    showDeleteDialog: false,
    employeeRatings: {},
    
});

const mutations = {
    SET_RESPONSIBLES(state: ResponsiblesState, payload: { responsibles: Responsibles[] }) {
        state.responsibles = payload.responsibles;
    },
    SHOW_FORM_DIALOG(state: ResponsiblesState, payload: { show: boolean }) {
        state.showFormDialog = payload.show;
    },
    SET_SELECTED_RESPONSIBLE(state: ResponsiblesState, payload: { responsibles: Responsibles }) {
        state.selectedResponsible = Object.assign({}, payload.responsibles);
    },
    SET_FORM_MODE(state: ResponsiblesState, payload: { mode: FormMode }) {
        state.formMode = payload.mode;
    },
    SET_EMPLOYEE_RATINGS(state: ResponsiblesState, payload: { employeeRatings: { [key: string]: number } }) {
        state.employeeRatings = payload.employeeRatings;
    }
};

const actions = {
    openEditResponsibleDialog({ commit }: ActionContext<ResponsiblesState, IRootState>, payload: { responsible: Responsibles }) {
        commit('SET_FORM_MODE', { mode: FormMode.EDIT });
        commit('SET_SELECTED_RESPONSIBLE', { responsibles: payload.responsible });
        commit('SHOW_FORM_DIALOG', { show: true });
    },
    openViewResponsibleDialog({ commit }: ActionContext<ResponsiblesState, IRootState>, payload: { responsible: Responsibles }) {
        commit('SET_FORM_MODE', { mode: FormMode.VIEW });
        commit('SET_SELECTED_RESPONSIBLE', { responsibles: payload.responsible });
        commit('SHOW_FORM_DIALOG', { show: true });
    },
    openCreateDialog({ commit }: ActionContext<ResponsiblesState, IRootState>) {
        commit('SET_FORM_MODE', { mode: FormMode.ADD });
        commit('SET_SELECTED_RESPONSIBLE', { responsibles: defaultResponsibles });
        commit('SHOW_FORM_DIALOG', { show: true });
    },

    closeDialog({ commit }: ActionContext<ResponsiblesState, IRootState>) {
        commit('SHOW_FORM_DIALOG', { show: false });
    },
    async fetchResponsibles({ commit, state }: ActionContext<ResponsiblesState, IRootState>,) {
        try {
            const params: any = {};

            const response = await axiosAuth.get<DefaultResponse<Responsibles>>(`${endPoints.responsibles}`, { params });
            console.log(response.data.data);
            commit('SET_RESPONSIBLES', { responsibles: response.data.data });
        } catch (error) {
            notifyError('Erro ao buscar responsáveis');
        }
    },
    async saveResponsible({ state,commit }: ActionContext<ResponsiblesState, IRootState>) {
        try {
            let response;
            if (state.formMode === FormMode.ADD) {
                delete state.selectedResponsible.id;
                response = await axiosAuth.post<DefaultResponse<Responsibles>>(endPoints.responsibles, state.selectedResponsible);
                commit('SET_SELECTED_RESPONSIBLE', { responsibles: response.data });
            } else {
                response = await axiosAuth.put<DefaultResponse<Responsibles>>(`${endPoints.responsibles}/${state.selectedResponsible.id}`, state.selectedResponsible);
            }
            notifySuccess('Avaliação salva com sucesso');
        } catch (error) {
            notifyError('Erro ao salvar responsável');
        }
    },
    async updateResponsible({ state, commit }: ActionContext<ResponsiblesState, IRootState>) {
        try {
            const data = state.selectedResponsible;
            delete data.client;
            const response = await axiosAuth.put<DefaultResponse<Responsibles>>(`${endPoints.responsibles}/${state.selectedResponsible.id}`, state.selectedResponsible);
            commit('SET_SELECTED_RESPONSIBLE', { responsibles: response.data });
        } catch (error) {
            notifyError('Erro ao atualizar responsável');
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};